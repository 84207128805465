
import { computed, defineComponent, inject, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import Accordion from '@/components/util/Accordion.vue';
import Bar from '@/components/Bar.vue';
import { useRouter } from 'vue-router';
import UserDropdown from '@/components/custom/UserDropdown.vue';
import { Emitter } from 'mitt';
import EntwicklungsPlan from '@/components/EntwicklungsPlan.vue';
import FileList from '@/components/FileList.vue';
import Zusammenarbeit from '@/components/custom/Zusammenarbeit.vue';
import TableTabs from '@/components/TableTabs.vue';

type GradeResult = {
  target?: number;
  set?: number;
  value?: number;
  leader?: number;
  self?: number;
  history?: number;
  history_set?: number;
  detailed?: {
    [propName: number]: number;
  };
};

export interface SkillRecord {
  id: number;
  name: string;
  description: string;
  group: 'personal' | 'social' | 'working';
  type: 1 | 2 | 3;
  strategic: Array<number>;
  future: Array<number>;
  leadership: boolean;
}

export default defineComponent({
  components: { Accordion, Bar, UserDropdown, EntwicklungsPlan, FileList, Zusammenarbeit, TableTabs },
  setup() {
    const store = useStore();
    const router = useRouter();
    const mitt = inject('emitter') as Emitter<{ awayfromdetails: string }>;
    const loading = ref(true);
    const fixed = ref(true);

    const barTableFilter = ref('avg');
    const skills = ref<Array<SkillRecord>>([]);
    const barTable = ref<{ [propName: number]: GradeResult }>({});
    const user = ref();
    const gradingusers = ref([]);
    const isManager = ref(false);

    const id = computed(() => {
      if (!Array.isArray(router.currentRoute.value.params.id)) return parseInt(router.currentRoute.value.params.id);
      return undefined;
    });

    onMounted(() => {
      store.dispatch('fetchPersonalGrades', id.value).then((res: any) => {
        barTable.value = res.result;
        user.value = { ...res.meta.user, unit_id: parseInt(res.meta.user.unit_id) };

        skills.value = res.meta.skills
          .sort(store.getters.getSort(user.value.unit_id))
          .filter((item: SkillRecord) => !item.leadership || res.meta.leadershipskills);
        gradingusers.value = res.meta.gradingusers;
        isManager.value = res.meta.isManager || false;
        loading.value = false;
      });
    });

    const filteredSkills = (type: 'personal' | 'social' | 'working') => {
      return skills.value.filter((item: SkillRecord) => item.group == type);
    };

    const filteredBarTable = computed(() => {
      const table: { [propType: string]: { value: number; target: number; history: number } } = {};
      for (const index in barTable.value) {
        const item = barTable.value[index];

        switch (barTableFilter.value) {
          case 'avg':
            mitt.emit('awayfromdetails', '');
            table[index] = { value: item.value || -1, target: item.target || 0, history: item.history || -1 };
            break;
          case 'manager':
            mitt.emit('awayfromdetails', '');
            table[index] = { value: item.leader || -1, target: item.target || 0, history: item.history || -1 };
            break;
          case 'set':
            mitt.emit('awayfromdetails', '');
            table[index] = { value: item.set || -1, target: item.target || 0, history: item.history_set || -1 };
            break;
          case 'self':
            mitt.emit('awayfromdetails', '');
            table[index] = { value: item.self || -1, target: item.target || 0, history: item.history || -1 };
            break;
          default:
            table[index] = {
              value: item.detailed ? item.detailed[parseInt(barTableFilter.value)] : -1,
              target: item.target || 0,
              history: item.history || -1,
            };
            break;
        }
      }

      return table;
    });

    const filterTable = (filter: string) => {
      if (filter == '-1') {
        barTableFilter.value = 'avg';
      } else {
        barTableFilter.value = filter;
      }
    };

    const fullname = computed(() => {
      return '';
    });

    return {
      filterTable,
      types: store.getters.getTypes,
      loading,
      user,
      fixed,
      isManager,
      filteredBarTable,
      barTableFilter,
      filteredSkills,
      fullname,
      gradingusers,
    };
  },
});
