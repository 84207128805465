
import { defineComponent, inject, ref } from 'vue';
import { mdiPlus } from '@mdi/js';
import { Emitter } from 'mitt';

export default defineComponent({
  props: {
    title: { type: String, required: true },
  },
  setup(props) {
    const id = '_' + Math.random().toString(36).slice(-2);
    const isOpen = ref(true);
    const mitt = inject('emitter') as Emitter<{ open: string }>;

    const headerClick = () => {
      isOpen.value = !isOpen.value;
      //   localStorage.setItem(`${props.group}-tableopen`, isOpen.value ? 'open' : 'closed');
    };

    return {
      id,
      isOpen,
      headerClick,
      mdiPlus,
    };
  },
});
